import React from 'react';
import Home from './Page/Home';
import SoundMuseum from './Page/SoundMuseum';
import './App.css';
// import About from './Page/About';

function App() {

return (
  <div className="App">
    <Home />
    {/* <SoundMuseum /> */}
    {/* <About /> */}
  </div>
);
}

export default App;
