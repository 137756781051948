import React, { useState, useRef, useEffect } from 'react';
import './Home.css';
import TopLinks from '../Component/TopLinks';
import character from '../assets/CR2.png';
import soundFile from '../assets/sui.mp3'; // Import sound file
import speakerIcon from '../assets/speaker.svg';

const Home = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [texts, setTexts] = useState([]); // Store multiple scrolling texts
  const [pageLoaded, setPageLoaded] = useState(false); // Page load state for animation

  // Function to handle button click
  const handleButtonClick = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      setTexts([{ position: 100, top: getRandomTopPosition() }]); // Start the first text with random top position
      audioRef.current.play(); // Play sound
    }
  };

  const getRandomTopPosition = () => {
    // Get a random top position between 10vh and 80vh (to avoid text being too high or too low)
    return Math.random() * 70 + 10;
  };

  useEffect(() => {
    let interval;

    if (isPlaying) {
      interval = setInterval(() => {
        setTexts((prevTexts) => {
          const updatedTexts = prevTexts.map((text) => ({
            ...text,
            position: text.position - 1, // Increased speed by decrementing faster
          }));

          // Only add a new text if the previous one is almost fully out of screen
          if (updatedTexts.length === 0 || updatedTexts[updatedTexts.length - 1].position <= -40) {
            updatedTexts.push({ position: 100, top: getRandomTopPosition() }); // Add new text with random top position
          }

          // Remove texts that are fully off the screen
          return updatedTexts.filter((text) => text.position > -100); // Wait until it's fully off the screen
        });
      }, 5); // Update position every 10 milliseconds (for smooth animation)
    }

    return () => {
      clearInterval(interval); // Clean up on unmount
    };
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setIsPlaying(false); // Stop scrolling when audio ends
        setTexts([]); // Clear all texts
      };
    }
  }, []);

  // Add effect to trigger page load animations
  useEffect(() => {
    setPageLoaded(true);
  }, []);

  return (
    <div className="Home">
      <TopLinks />
      <div className={`HomeContentContainer ${pageLoaded ? 'fade-in' : ''}`}>
        <div className={`HomeContentDescription ${pageLoaded ? 'pop-in' : ''}`}>
          <div className="HomeContentText">
            <div className="HomeContentTextTitle">
              <div className="titleHome">
                SUUUUIIII
              </div>
              <div className="triggerButtonContainer" onClick={handleButtonClick}>
                <div className="triggerButton">
                  SUUUUUUUUUUIIIIIII!
                </div>
                <img className="triggerButtonSpeakerIcon" src={speakerIcon} alt="speaker"/>
              </div>
            </div>
          </div>
          <a href="https://pump.fun/board" className='HomeContentBuyButtonLink' target="_blank" rel="noopener noreferrer">
            BUY
          </a>
        </div>
        <div className={`HomeContentImageContainer ${pageLoaded ? 'pop-in' : ''}`}>
          <img className="HomeContentImage" src={character} alt="character" />
        </div>
      </div>

      {/* Scrolling Texts */}
      {isPlaying && texts.map((text, index) => (
        <div
          key={index}
          className="scrollingText"
          style={{ left: `${text.position}vw`, top: `${text.top}vh` }} // Use dynamic position for each text
        >
          SUUUUUUUUUUUUUUUUUUUUUUIIIIIIIIIIIIIIIIIIIIII!
        </div>
      ))}

      {/* Audio element */}
      <audio ref={audioRef} src={soundFile} />
    </div>
  );
};

export default Home;
